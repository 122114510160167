import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { AppContext } from 'store/app.context';
import { useHistory } from 'react-router-dom';

export const MatchingJobsCard = ({ job }) => {
    const history = useHistory()
    const { languageState } = useContext(AppContext);
    // const urlToShare = `${API_BASE_URL}jobs/share/${props.id}`

    const [allSkills, setAllSkills] = useState([])
    const [tooltipIndex, setTooltipIndex] = useState(null)



    useEffect(() => {
        if (job) {
            const array = new Set([
                ...(job.missionsSkills || []),
                ...((job.tags || []).map((el) => ({ id: el, key: el }))),
            ]);

            const sortedArray = Array.from(array);

            const displayArray = sortedArray.slice(0, 3);
            const hasMore = sortedArray.length -  displayArray.length;

            setAllSkills( hasMore
              ? [...displayArray, { key: `+${hasMore} others`, id: 'more' }]
              : displayArray);
        }

    }, [job])
    return (
        <div className="card p-3 shadow-sm w-100 d-flex flex-direction-column justify-content-between">
            <div className="text-muted mb-2" style={{ fontSize: '0.8rem',fontWeight: 'bold' }}>
                <p className="mb-0 font-weight-bold">
                    {languageState.translation.COMMON.PUBLISHED}: {moment(job.createdAt).format('DD MMMM YYYY h:mmA')}
                </p>
            </div>


            <div className="job-details">
                {job?.city && (
                    <div className="job-details-item">
                        <i className="fas fa-map-marker-alt fa-sm" />
                        <p className="details-text">
                            {job?.city + ', ' + job?.country}
                        </p>
                    </div>
                )}
                {job?.hoursPerWeek > 35 ? (
                    <div className="job-details-item">
                        <i className="fas fa-clock fa-sm"></i>
                        <p className="details-text">
                            {languageState.translation.COMMON.FULL_TIME}
                        </p>
                    </div>) : <div className="job-details-item">
                    <i className="fas fa-clock fa-sm"></i>
                    <p className="details-text">
                        {languageState.translation.COMMON.PART_TIME}
                    </p>
                </div>}
                {job?.remote && (
                    <div className="job-details-item">
                        <i className="fas fa-home fa-sm"></i>
                        <p className="location-text">{languageState.translation.COMMON.REMOTE}</p>
                    </div>
                )}
            </div>

            <div className="company-details m-1">
                <h3> {job?.functionTitle || '---'}</h3>
            </div>

            {allSkills && allSkills.length ? (
                <div className="required-skills mb-2">
                    <p className="required-skills-text">{languageState.translation.COMMON.REQUIRED_SKILLS}:</p>
                    {allSkills?.map((missionSkill) => (
                        <div key={missionSkill.id} className="skill">
                            <p className="skill-text text-nowwrap">
                                {missionSkill.key}
                            </p>
                        </div>
                    ))}
                </div>
            ) : <div className="required-skills ml-2 mb-2">
                <p>{languageState.translation.COMMON.NO_REQUIRED_SKILLS}</p>
            </div>}


            <div className="d-flex justify-content-between">
                <button className="btn btn-exprimary btn-sm w-100" style={{ borderRadius: '0' }} 
                onClick={() => {
                    sessionStorage.setItem("previousUrl", "dashboard");
                    history.push(`/private/jobs/job-details/${job.id}`)
                }}>
                    {languageState.translation.COMMON.SHOW_DETAILS}</button>
                <button className="btn btn-primary  btn-sm w-100" style={{ borderRadius: '0' }} 
               onClick={() => {
                history.push({
                  pathname: '/private/chat',
                  state: { pathJob: `${window.location.origin}/private/jobs/job-details/${job.id}` }
                })
              }}>{languageState.translation.COMMON.SHARE_WITH_FRIEND}</button>
            </div>
        </div>
    );
};

