import BreadcrumbComponent from 'components/breadcrumb';
import { IMG_PREFIX } from 'config/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Container, Row } from 'reactstrap';
import { AppContext } from 'store/app.context';

export const ProfileHeaderCard = ({ name, user, previousPath }) => {
    const { languageState, userState } = useContext(AppContext);
    const history = useHistory();

    return (
        <>
            <div className="d-flex flex-column justify-content-center header pb-4 rounded px-4 shadow">
                <div className='return-overview-page-link' >
                    <BreadcrumbComponent breadCrumbActive={name} breadCrumbLinks={[{ label: languageState.translation.COMMON.RETURN_TO_OVERVIEW, link: previousPath, type: 'return_to_previous' }]} homeJobs={undefined} />
                </div>
                <Container
                    className="d-flex pt-6 ml-3"
                    fluid
                >
                    <Row className="d-flex gap-1 align-items-center justify-content-center">
                        <div className="rounded-img-container">
                            <img className="rounded-img-profile object-fit-cover"
                                src={
                                    user?.profileImage
                                        ? `${IMG_PREFIX}${user.profileImage}`
                                        : 'assets/img/man.png'
                                }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = require('assets/img/man.png').default;
                                }}
                            />
                        </div>
                        <div className='d-flex flex-column  align-items-center align-items-sm-start'>
                            <h1 className="text-bold text-xl text-white mb-1">{name}</h1>
                            {
                                user.region
                                    ? <p className="text-muted d-flex align-items-center text-bold mb-1" style={{ gap: 5 }}>
                                        <i className="ni ni-world" />
                                        {user.city
                                            ? <span>{`${user.city},`}</span>
                                            : null
                                        }
                                        <label>{user.region}</label>
                                    </p>
                                    : null
                            }
                            {user?.workingTime && (
                                <p className="text-white ">
                                    <span className="font-weight-bold font-small-4">{languageState.translation.COMMON.TYPE} :</span>{' '}
                                    {user?.workingTime === 'FULL_TIME'
                                        ? 'Full Time'
                                        : 'Part Time'}{' '}
                                    {user?.profession && languageState.translation.STEPPER.FIRST_STEP_OPTIONS[user.profession]}
                                </p>
                            )}
                            {/*!history.location.pathname.includes('/public') ? ((userState?.userInfo?.kind === 'COMPANY') || (userState.userInfo.id === user.id)) && user?.resume && (
                                <p className="text-white mt-2">
                                    <span className="font-weight-bold font-small-4">CV :</span>{' '}
                                    <Badge className='bg-primary-light'>
                                        <i className='fa fa-link mr-2' />
                                        <a className='text-primary' target='_blank' href={`${IMG_PREFIX}${user.resume}`}>{user.resume}</a>
                                    </Badge>
                                </p>
                            ) : null*/}
                        </div>
                    </Row>

                </Container>

            </div >
        </>
    );
};

