const APP_ROUTES = {
  HOME: {
    HOME: '/home',
    DASHBOARD: '/home/dashboard',
    CALENDAR: '/home/calendar',
    CLIENTS: '/home/clients',
  },
  RECRUITER: {
    DASHBOARD: '/home',
    MISSIONS: '/missions',
    NETWORK: '/network',
    SYNC: '/sync',
    SHARE_ONBOARDING: '/share-onboarding',
    IMPORT_FREELANCERS: '/import-freelancers',
    ADD_MISSION: '/add-mission',
    EDIT_MISSION: '/edit-mission/:id',
    FORM: '/recruiter/form',
    MISSIONS_INFO: '#',
    MISSIONS_APPLICANTS: '/missions/applicants',
    MISSION_OVERVIEW: '/mission',
    MISSION_CONTRACTORS: '/missions/contractors',
  },

  CONNECT: {
    CONNECT: '/connect',
    ADD_CONTACT: '/connect/add-contact',
    ADD_MULTIPLE_CONTACTS: '/connect/add-multiple-contacts',
    ALL_NETWORK: '/connect/network',
    YOUR_NETWORK: '/connect/my-network',
    GROUPS: '/connect/groups',
    TAGGED: '/connect/tagged',
    FAVORITES: '/connect/favorites',
    CATEGORIES: '/connect/categories',
    IMPORT_FREELANCERS: '/connect/import-freelancers',
    PROFILE_DETAILS: '/connect/network/profile',
    RESUME: '/connect/network/resume',
    AVAILIBILITY: '/connect/network/availibility',
    PORTFOLIO: '/connect/network/portfolio',
    YOUR_TASKS: '/connect/your-tasks'
  },
  GROW: {
    DEALS: '/grow/deals',
    LEARN: '/grow/learn',
    JOBS: '/grow/team',
    EVENTS: '/grow/events',
  },
  JOBS: {
    BOARD: '/jobs/jobboard',
    COMPANIES: '/jobs/companies',
    // MICROJOBS: '/jobs/microjobs',
    JOB_DETAILS: '/jobs/job-details/:id',
    POST_JOB: '/jobs/post-job',
  },
  
  TOOLS:{
    PENDING_APPLICATIONS :'/tools/pending_applications',
    INVOICE:'/tools/generate-invoice',
  },
  LEAD_DETAILS: '/leads/lead-details/:id',
  PAYMENTS: '/payments',
  FOCUS: {
    FOCUS: '/focus',
    DASHBOARD: '/focus/dashboard',
    TEAM: '/focus/team',
    NOTES: '/focus/notes',
    CALENDAR: '/focus/calendar',
    ON_BOARDING: '/focus/onboarding',
    REC: '/fcocus/rec',
  },
  EXTRA: '/extra',
  MISSIONS: '/missions',
  PROMOTE: '/promote',
  AUTH: {
    login: '/auth/login',
    register: '/auth/register',
    invitedUser: 'register/invited-user/',
  },
  PROFILE: '/profile',
  SETTINGS: '/settings',
  CHAT: '/chat',
  PUBLIC_ROUTE: {
    PROFILE: {
      PROFILE_DETAILS: '/connect/network/profile'
    },
    JOBS: {
      BOARD: '/jobs/jobboard',
      JOB_DETAILS: '/job-details/:id',
    }
  },
  UPGRADE_PLAN:'/upgrade_plan'

};

export default APP_ROUTES;
