import Favorites from 'pages/connect/favorites';
import Groups from 'pages/connect/groups';
import Availibilty from 'pages/connect/inner-pages/availibility';
import ProfileDetails from 'pages/connect/inner-pages/profile-details';
import Tagged from 'pages/connect/tagged';
import FocusDashboard from 'pages/focus/dashboard';
import OnBoarding from 'pages/focus/on-boarding';
import Team from 'pages/focus/team';
import HomeContainer from 'pages/home';
import HomeCalendar from 'pages/home/calendar';
import Dashboard from 'pages/home/dashboard';
import APP_ROUTES from './app.routes';
import Profile from '../pages/profile';
import YourNetworkContainer from 'pages/connect/your-network/container';
import { recruiterDashboard } from 'pages/recruiter/recruiterDashboard/recruiterDashboard';
import Shareonboarding from 'pages/recruiter/onboarding/Shareonboarding';
import Sync from 'pages/recruiter/sync/Sync';
import Settings from 'pages/settings/settings';
import FreelancerDeals from 'pages/grow/deals';
import Jobboard from 'pages/jobs/jobboard';
import Import from 'pages/recruiter/import/import';
import MissionOverviewContainer from 'pages/recruiter/mission-overview/container';
import MissionInfo from 'pages/recruiter/mission-overview/informations/mission-info';
import MissionApplicant from 'pages/recruiter/mission-overview/applicants/mission-appli';
import AddEditMission from 'pages/recruiter/Addmission/Addmission.jsx';
import Chat from 'pages/chat/chat';
import Payments from 'pages/payments/Payments';
import AddContact from 'pages/connect/add-contact/AddContact';
import AddOneContact from 'pages/connect/add-contact/AddOneContact';
import Contractors from 'pages/recruiter/mission-overview/contractos/contractors';
import MissionOverview from 'pages/recruiter/mission-overview/mission-overview';
// import Company from 'pages/jobs/companies/Company';
import MicroJobs from 'pages/jobs/micro-jobs/MicroJobs';
import JobDetails from 'pages/jobs/job-details/JobDetails';
import PostJobs from 'pages/jobs/post-job/PostJobs';
import Resume from 'pages/connect/inner-pages/resume';
import SalesPipeline from 'components/sales-pipeline/SalesPipeline'
import {LeadDetails} from 'components/ModalAddLead/leadDetails';
import { Invoice } from 'components/invoice/invoice';
import { UpgradePlan } from 'components/upgradePlan/upgradePlan';

const routes = [
  // HOME page FREELANCER | RECRUITER | COMPANY
  {
    collapse: false,
    path: APP_ROUTES.HOME.HOME,
    name: 'HOME',
    layout: '/private',
    component: Dashboard,
    icon: 'ni ni ni-compass-04 text-red',
    state: 'dashboardsCollapse',
    role: 'FREELANCER',
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.DASHBOARD,
    name: 'HOME',
    layout: '/private',
    component: recruiterDashboard,
    icon: 'ni ni ni-compass-04 text-red',
    state: 'dashboardsCollapse',
    role: 'RECRUITER',
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.DASHBOARD,
    name: 'HOME',
    layout: '/private',
    component: recruiterDashboard,
    icon: 'ni ni ni-compass-04 text-red',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
  },
  //INVOICE page FREELANCE
  {
    collapse: false,
    path: APP_ROUTES.INVOICE,
    name: 'INVOICE',
    layout: '/private',
    component: Invoice,
    icon: 'ni ni ni-compass-04 text-red',
    state: 'dashboardsCollapse',
    role: 'FREELANCER',
  },
  // CHAT page FREELANCER | RECRUITER | COMPANY
  {
    parent: 'CHAT',
    collapse: false,
    path: APP_ROUTES.CHAT,
    name: 'CHAT',
    layout: '/private',
    component: Chat,
    icon: 'ni ni-chat-round text-red',
    role: 'FREELANCER',
    hide: false,
  },
  {
    parent: 'CHAT',
    collapse: false,
    path: APP_ROUTES.CHAT,
    name: 'CHAT',
    layout: '/private',
    component: Chat,
    icon: 'ni ni-chat-round text-red',
    role: 'RECRUITER',
  },
  {
    parent: 'CHAT',
    collapse: false,
    path: APP_ROUTES.CHAT,
    name: 'CHAT',
    layout: '/private',
    component: Chat,
    icon: 'ni ni-chat-round text-red',
    role: 'COMPANY',
  },
  // MISSION module COMPANY
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSIONS,
    name: 'MISSIONS',
    layout: '/private',
    component: MissionOverviewContainer,
    icon: 'ni ni-bullet-list-67 text-red',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.ADD_MISSION,
    name: 'ADD_MISSION',
    layout: '/private',
    component: AddEditMission,
    icon: 'ni ni-shop text-red',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.EDIT_MISSION,
    name: 'EDIT_MISSION',
    layout: '/private',
    component: AddEditMission,
    icon: 'ni ni-shop text-red',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.EDIT_MISSION,
    name: 'OVERVIEW',
    layout: '/private',
    component: MissionOverview,
    icon: 'ni ni-shop text-primary',
    role: 'COMPANY',
    hide: true,
    exact: true,
  },
  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSIONS_APPLICANTS + '/:id',
    name: 'APPLICANTS',
    layout: '/private',
    component: MissionApplicant,
    icon: 'ni ni-shop text-red',
    role: 'COMPANY',
    hide: true,
    exact: true
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSION_CONTRACTORS + '/:id',
    name: 'MISSIONS_OVERVIEW',
    parent: 'MISSIONS_OVERVIEW',
    layout: '/private',
    component: Contractors,
    icon: 'ni ni-bullet-list-67 text-red',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  // MISSION module RECRUITER
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSIONS,
    name: 'MISSIONS',
    layout: '/private',
    component: MissionOverviewContainer,
    icon: 'ni ni-bullet-list-67 text-red',
    state: 'dashboardsCollapse',
    role: 'RECRUITER',
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.ADD_MISSION,
    name: 'ADD_MISSION',
    layout: '/private',
    component: AddEditMission,
    icon: 'ni ni-shop text-red',
    state: 'dashboardsCollapse',
    role: 'RECRUITER',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.EDIT_MISSION,
    name: 'EDIT_MISSION',
    layout: '/private',
    component: AddEditMission,
    icon: 'ni ni-shop text-red',
    state: 'dashboardsCollapse',
    role: 'RECRUITER',
    hide: true,
  },
  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.EDIT_MISSION,
    name: 'OVERVIEW',
    layout: '/private',
    component: MissionOverview,
    icon: 'ni ni-shop text-primary',
    role: 'RECRUITER',
    hide: true,
    exact: true,
  },
  {
    parent: 'MISSION_OVERVIEW',
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSIONS_APPLICANTS + '/:id',
    name: 'APPLICANTS',
    layout: '/private',
    component: MissionApplicant,
    icon: 'ni ni-shop text-red',
    role: 'RECRUITER',
    hide: true,
    exact: true
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.MISSION_CONTRACTORS + '/:id',
    name: 'MISSIONS_OVERVIEW',
    parent: 'MISSIONS_OVERVIEW',
    layout: '/private',
    component: Contractors,
    icon: 'ni ni-bullet-list-67 text-red',
    state: 'dashboardsCollapse',
    role: 'RECRUITER',
    hide: true,
  },
  // FOCUS page FREELANCER | RECRUITER | COMPANY
  {
    parent: 'FOCUS',
    collapse: false,
    path: APP_ROUTES.FOCUS.DASHBOARD + '/:id',
    to: APP_ROUTES.FOCUS.DASHBOARD,
    name: 'DASHBOARD',
    layout: '/private',
    component: FocusDashboard,
    icon: 'ni ni-shop text-red',
    role: 'FREELANCER',
  },
  {
    parent: 'FOCUS',
    collapse: false,
    path: APP_ROUTES.FOCUS.TEAM + '/:id',
    to: APP_ROUTES.FOCUS.TEAM,
    name: 'TEAM',
    layout: '/private',
    component: Team,
    icon: 'ni ni-shop text-red',
    role: 'FREELANCER',
    hide: true
  },
  {
    parent: 'FOCUS',
    collapse: false,
    path: APP_ROUTES.FOCUS.REC + '/:id',
    to: APP_ROUTES.FOCUS.REC,
    name: 'recruiter',
    layout: '/private',
    component: Shareonboarding,
    icon: 'ni ni-shop text-red',
    role: 'FREELANCER',
    hide: true
  },
  {
    collapse: false,
    path: APP_ROUTES.FOCUS.FOCUS,
    name: 'FOCUS',
    icon: 'ni ni-bullet-list-67 text-red',
    state: 'focusCollapse',
    component: FocusDashboard,
    role: 'RECRUITER',
  },
  {
    parent: 'FOCUS',
    collapse: false,
    path: APP_ROUTES.FOCUS.TEAM + '/:id',
    to: APP_ROUTES.FOCUS.TEAM,
    name: 'TEAM',
    layout: '/private',
    component: Team,
    icon: 'ni ni-shop text-red',
    role: 'RECRUITER',
    hide: true
  },
  {
    parent: 'FOCUS',
    collapse: false,
    path: APP_ROUTES.FOCUS.REC + '/:id',
    to: APP_ROUTES.FOCUS.REC,
    name: 'recruiter',
    layout: '/private',
    component: Shareonboarding,
    icon: 'ni ni-shop text-red',
    role: 'RECRUITER',
    hide: true
  },
  // CONTACT page FREELANCER | RECRUITER | COMPANY
  {
    collapse: true,
    name: 'CONTACT',
    icon: 'ni ni-world-2 text-red',
    state: 'connectCollapse',
    role: 'FREELANCER',
    views: [
      {
        path: APP_ROUTES.CONNECT.ALL_NETWORK,
        name: 'ALL_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
        exact: true
      },
      {
        path: APP_ROUTES.CONNECT.YOUR_NETWORK,
        name: 'YOUR_CONTACT',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
        exact: true
      },
      {
        path: APP_ROUTES.CONNECT.GROUPS,
        name: 'YOUR_TEAMS',
        miniName: 'Gr',
        component: Groups,
        layout: '/private',
        iconLast: 'ni ni-fat-add text-exprimary',
      },
    ],
  },
  {
    collapse: true,
    name: 'CONTACT',
    icon: 'ni ni-world-2 text-red',
    state: 'connectCollapse',
    role: 'RECRUITER',
    views: [
      {
        path: APP_ROUTES.CONNECT.ALL_NETWORK,
        name: 'ALL_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
        exact: true
      },
      {
        path: APP_ROUTES.CONNECT.YOUR_NETWORK,
        name: 'YOUR_CONTACT',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
        exact: true
      },
      {
        path: APP_ROUTES.CONNECT.ADD_CONTACT,
        name: 'ADD_CONTACT',
        miniName: 'Yn',
        component: AddOneContact,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.ADD_MULTIPLE_CONTACTS,
        name: 'ADD_MULTIPLE_CONTACTS',
        miniName: 'Yn',
        component: AddContact,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.GROUPS,
        name: 'YOUR_TEAMS',
        miniName: 'Gr',
        component: Groups,
        layout: '/private',
        iconLast: 'ni ni-fat-add text-exprimary',
      },
      {
        path: APP_ROUTES.CONNECT.TAGGED,
        name: 'TAGGED',
        miniName: 'Ta',
        component: Tagged,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.FAVORITES,
        name: 'FAVORITES',
        miniName: 'Fa',
        component: Favorites,
        layout: '/private',
      },
    ],
  },
  {
    collapse: true,
    name: 'CONTACT',
    icon: 'ni ni-world-2 text-red',
    state: 'connectCollapse',
    role: 'COMPANY',
    views: [
      {
        path: APP_ROUTES.CONNECT.ALL_NETWORK,
        name: 'ALL_NETWORK',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
        exact: true
      },
      {
        path: APP_ROUTES.CONNECT.ADD_CONTACT,
        name: 'ADD_CONTACT',
        miniName: 'Yn',
        component: AddOneContact,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.ADD_MULTIPLE_CONTACTS,
        name: 'ADD_MULTIPLE_CONTACTS',
        miniName: 'Yn',
        component: AddContact,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.GROUPS,
        name: 'TALENTPOOLS',
        miniName: 'Gr',
        component: Groups,
        layout: '/private',
        iconLast: 'ni ni-fat-add text-exprimary',
      },
      {
        exact: true,
        path: APP_ROUTES.CONNECT.YOUR_NETWORK,
        name: 'YOUR_TALENTS',
        miniName: 'Yn',
        component: YourNetworkContainer,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.TAGGED,
        name: 'TAGGED',
        miniName: 'Ta',
        component: Tagged,
        layout: '/private',
      },
      {
        path: APP_ROUTES.CONNECT.FAVORITES,
        name: 'FAVORITES',
        miniName: 'Fa',
        component: Favorites,
        layout: '/private',
      },
    ],
  },
  // IMPORT_FREELANCERS page RECRUITER
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.IMPORT_FREELANCERS,
    name: 'IMPORT_FREELANCERS',
    layout: '/private',
    component: Import,
    icon: 'ni ni-badge text-red',
    state: 'dashboardsCollapse',
    role: 'RECRUITER',
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.IMPORT_FREELANCERS,
    name: 'IMPORT_FREELANCERS',
    layout: '/private',
    component: Import,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
  },
  // GROW page RECRUITER
  {
    collapse: true,
    name: 'GROW',
    icon: 'ni ni-user-run text-red',
    state: 'growCollapse',
    role: 'RECRUITER',
    hide: true,
    views: [
      {
        path: APP_ROUTES.GROW.DEALS,
        name: 'DEALS',
        miniName: 'Fd',
        component: FreelancerDeals,
        layout: '/private',
      },
      {
        path: APP_ROUTES.GROW.LEARN,
        name: 'LEARN',
        miniName: 'Fd',
        component: FreelancerDeals,
        layout: '/private',
      },
      {
        path: APP_ROUTES.GROW.EVENTS,
        name: 'EVENTS',
        miniName: 'Fd',
        component: FreelancerDeals,
        layout: '/private',
      },
    ],
  },

  //TOOLS page FREELANCER

  {
    collapse: true,
    name: 'TOOLS',
    layout: '/private',
    icon: 'ni ni-settings-gear-65 text-red',
    state: 'toolsCollapse',
    role: 'FREELANCER',
    views: [
      {
        collapse: false,
        path: APP_ROUTES.TOOLS.PENDING_APPLICATIONS,
        name: 'CRM',
        miniName: 'Pa',
        component: SalesPipeline,
        layout: '/private',
      },
      {
        collapse: false,
        path: APP_ROUTES.TOOLS.INVOICE,
        layout: '/private',
        name: 'INVOICES',
        miniName: 'In',
        component: Invoice,
        state: 'dashboardsCollapse',
        role: 'FREELANCER',
        iconLast: 'ni ni-fat-add text-exprimary',
      },
     {
        collapse: false,
        path: "/home",
        layout: '/private',
        name: 'CUSTOMERS',
        miniName: 'Cu',
        component: Invoice,
        state: 'customersCollapse',
        role: 'FREELANCER',
        iconLast: 'ni ni-fat-add text-exprimary',
      },
    ]
  },
  // JOBS page FREELANCER | RECRUITER | COMPANY
  {
    collapse: true,
    name: 'JOBS',
    layout: '/private',
    icon: 'ni ni-briefcase-24 text-red',
    state: 'jobsCollapse',
    role: 'FREELANCER',
    NBJobs: true,
    views: [
      {
        collapse: false,
        path: APP_ROUTES.PUBLIC_ROUTE.JOBS.BOARD,
        name: 'FREELANCE_JOBS',
        miniName: 'Fj',
        component: Jobboard,
        layout: '/private',
      },
   
    ]
  },
  {
    collapse: true,
    name: 'JOBS',
    icon: 'ni ni-briefcase-24 text-red',
    state: 'jobsCollapse',
    role: 'RECRUITER',
    NBJobs: true,
    views: [
      {
        collapse: false,
        path: APP_ROUTES.PUBLIC_ROUTE.JOBS.BOARD,
        name: 'BOARD',
        miniName: 'Fj',
        component: Jobboard,
        layout: '/private',
      },
     
    ]
  },
  {
    collapse: true,
    name: 'JOBS',
    icon: 'ni ni-briefcase-24 text-red',
    state: 'jobsCollapse',
    role: 'COMPANY',
    views: [
      {
        path: APP_ROUTES.PUBLIC_ROUTE.JOBS.BOARD,
        name: 'BOARD',
        miniName: 'Jb',
        component: Jobboard,
        layout: '/private',
        NBJobs: true
      },
      {
        collapse: false,
        path: APP_ROUTES.RECRUITER.ADD_MISSION,
        name: 'POST_JOB',
        miniName: 'Ms',
        component: AddEditMission,
        layout: '/private',
      },
    ],
  },
  // JOBS_DETAILS page FREELANCER | RECRUITER | COMPANY
  {
    layout: '/private',
    path: APP_ROUTES.JOBS.JOB_DETAILS,
    name: 'JOB_DETAILS',
    component: JobDetails,
    role: 'FREELANCER',
    hide: true
  },
  {
    layout: '/private',
    path: APP_ROUTES.JOBS.JOB_DETAILS,
    name: 'JOB_DETAILS',
    component: JobDetails,
    role: 'RECRUITER',
    hide: true
  },
  {
    layout: '/private',
    path: APP_ROUTES.JOBS.JOB_DETAILS,
    name: 'JOB_DETAILS',
    component: JobDetails,
    role: 'COMPANY',
    hide: true
  },

  
  // SETTINGS page FREELANCER | RECRUITER | COMPANY
  {
    parent: 'SETTINGS',
    collapse: false,
    path: APP_ROUTES.SETTINGS,
    name: 'SETTINGS',
    layout: '/private',
    component: Settings,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'SETTINGS',
    collapse: false,
    path: APP_ROUTES.SETTINGS,
    name: 'SETTINGS',
    layout: '/private',
    component: Settings,
    icon: 'ni ni-shop text-primary',
    role: 'RECRUITER',
    hide: true,
  },
  {
    parent: 'SETTINGS',
    collapse: false,
    path: APP_ROUTES.SETTINGS,
    name: 'SETTINGS',
    layout: '/private',
    component: Settings,
    icon: 'ni ni-shop text-primary',
    role: 'COMPANY',
    hide: true,
  },
  // PROFILE page FREELANCER | RECRUITER | COMPANY
  {
    parent: 'YOUR_NETWORK',
    collapse: false,
    path: APP_ROUTES.CONNECT.PROFILE_DETAILS + '/:id',
    name: 'PROFILE_DETAILS',
    layout: '/private',
    component: ProfileDetails,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'YOUR_NETWORK',
    collapse: false,
    path: APP_ROUTES.CONNECT.PROFILE_DETAILS + '/:id',
    name: 'PROFILE_DETAILS',
    layout: '/private',
    component: ProfileDetails,
    icon: 'ni ni-shop text-primary',
    role: 'RECRUITER',
    hide: true,
  },
  {
    parent: 'YOUR_NETWORK',
    collapse: false,
    path: APP_ROUTES.CONNECT.PROFILE_DETAILS + '/:id',
    name: 'PROFILE_DETAILS',
    layout: '/private',
    component: ProfileDetails,
    icon: 'ni ni-shop text-primary',
    role: 'COMPANY',
    hide: true,
  },
  //  PAYMENTS & AVAILABILITY & RESUME page FREELANCER
  {
    parent: 'PROFILE',
    collapse: false,
    path: APP_ROUTES.PROFILE + "/id",
    name: 'PROFILE',
    layout: '/private',
    component: Profile,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    parent: 'PAYMENETS',
    collapse: false,
    path: APP_ROUTES.PAYMENTS,
    name: 'PAYMENTS',
    layout: '/private',
    component: Payments,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.CONNECT.AVAILIBILITY + '/:id',
    name: 'AVAILIBILITY',
    layout: '/private',
    component: Availibilty,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true
  },
  {
    collapse: false,
    path: APP_ROUTES.CONNECT.RESUME + '/:id',
    name: 'RESUME',
    layout: '/private',
    component: Resume,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true
  },
  // SHARE_ONBOARDING page RECRUITER | COMPANY   
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.SHARE_ONBOARDING,
    name: 'SHARE_ONBOARDING',
    layout: '/private',
    component: Shareonboarding,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'RECRUITER',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.SHARE_ONBOARDING,
    name: 'SHARE_ONBOARDING',
    layout: '/private',
    component: Shareonboarding,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },
  // Synchronize_FREELANCERS page RECRUITER | COMPANY 
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.SYNC,
    name: 'NETWORK',
    iconLast: 'ni ni-fat-add text-exprimary',
    layout: '/private',
    component: Sync,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'RECRUITER',
    hide: true,
  },
  {
    collapse: false,
    path: APP_ROUTES.RECRUITER.SYNC,
    name: 'NETWORK',
    iconLast: 'ni ni-fat-add text-exprimary',
    layout: '/private',
    component: Sync,
    icon: 'ni ni-shop text-primary',
    state: 'dashboardsCollapse',
    role: 'COMPANY',
    hide: true,
  },

  // CALENDAR page FREELANCER
  {
    collapse: false,
    name: 'CALENDAR',
    path: APP_ROUTES.HOME.CALENDAR,
    layout: '/private',
    component: HomeCalendar,
    icon: 'ni ni-shop text-primary',
    role: 'FREELANCER',
    hide: true,
  },

  //lead DETAILS page FREELANCER
  {
    layout: '/private',
    path: APP_ROUTES.LEAD_DETAILS,
    name: 'LEAD_DETAILS',
    component: LeadDetails,
    role: 'FREELANCER',
    hide: true
  },
  //UPGRADE PLAN for freelancer
  
  {
    collapse: false,
    path: APP_ROUTES.UPGRADE_PLAN,
    name: 'UPGRADE_PLAN',
    layout: '/private',
    component: UpgradePlan,
    icon: 'ni ni-bold-up text-danger',
    role: 'FREELANCER',
    hide: false,
  },
];


export const innerRoutesFreelancer = routes.filter((route) => route.role === 'FREELANCER');
export const innerRoutesCompany = routes.filter((route) => route.role === 'COMPANY');

export const innerRoutesRecruiter = routes.filter((route) => route.role === 'RECRUITER');

export default routes;
