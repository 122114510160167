import { useContext } from "react"
import { AppContext } from "store/app.context"
import CLIENTS_STATE from "store/clients/clients.state"


export const useInvoiceService = () => {
const {dispatchClientsState} = useContext(AppContext)
    const getInvoiceData = () => {
        return
    }

    const addInvoiceData = (data) => {
            return
        }
const getClients = () => {
    const clients = JSON.parse(sessionStorage.getItem('newClientInfo')) || [];
    dispatchClientsState({
        type:CLIENTS_STATE.types.SET_CLIENTS,
        clients: [{
            companyName: "Demo",
            alias: "",
            companyIban: "BE71096123456769",
            VATNumber: "BE123467889",
            address: {
                street: "Sparrendreef",
                postalCode: "2800",
                city: "Mechelen",
                country: "België",
                houseNumber: "14"
            },
        },...clients]
    })
 
}
    return {
        getInvoiceData,
        addInvoiceData,
        getClients
    }
}