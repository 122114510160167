import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import JobsContainer from '../JobsContainer';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CardsHeaderFocus from 'components/cards/card-header-focus';
import useJobsService from 'services/jobs/jobs.service';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/nl';
import { toast } from 'react-toastify';
import { DEFAULT_PRIVATE_PATH } from 'config/core';
import APP_ROUTES from 'navigation/app.routes';
import { AppContext } from 'store/app.context';
import Empty from 'components/Empty/Empty';
import LoaderPage from 'components/LoaderPage/LoaderPage';
import './JobDetails.scss';
import JobCard from '../jobboard/JobCard';
import ExEditor from 'components/editor/editor';
import { getQueryParamsFromObject } from 'utils';
import { useSalesPipelineService } from 'services/sales-pipeline/sales-pipeline.service';
import APPLICATIONS_STATE from 'store/applications/applications.state';
import useUserService from 'services/profile/user.service';
import { UpgradePremiumModal } from 'components/modalUpgradePremium/modalUpgradePremium';
import useDashboardService from 'services/dashboard/dashboard.service';
import DASHBOARD_STATE from 'store/dashboard/dashboard.state';

const JobDetails = () => {
  const { getJobDetail, getJobsSaved, checkIsApplied, applyJob, deleteApplication, postViewJob, saveNewJob, checkIsSaved, deleteSaved } = useJobsService();
  const { updateExtraData, addNote, getNotes, getAllApplications } = useSalesPipelineService();
  const {getSubscriptionsByUser} = useUserService();
  const { getDashBoardStats } = useDashboardService();
  const history = useHistory();
  const { userState, languageState, jobsState, dispatchApplicationState, dashboardState, dispatchDashboardState } = useContext(AppContext);
  moment.locale(languageState.defaultLanguage.toLowerCase());
  const [isLoading, setIsLoading] = useState(false);
  const [myJob, setMyjob] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [personalMessage, setPersonalMessage] = useState('')
  const { userInfo } = userState;
  const [job, setJob] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  const [isSaved, setIsSaved] = useState(false)
  const [requirementsError, setRequirementsError] = useState(false)
  const [allSkills, setAllSkills] = useState([])
  const [jobSaved, setJobSaved] = useState([])
  const [estimation, setEstimation] = useState('')
  const [alias, setAlias] = useState('')
  const [notes, setNotes] = useState([]);
  const [note, setNote] = useState('');
  const [application, setApplication] = useState(null)
  const [isAddingNote, setIsAddingNote] = useState(false);
const [previousUrl, setPreviousUrl] = useState([])
const [isValidPersonalMessage, setIsValidPersonalMessage] = useState(false)
const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
const [membershipState, setMembershipState] = useState(null);

  const { id } = useParams();
  const prevUrl = sessionStorage.getItem("previousUrl");

  const getBreadcrumbPath = () => {
    switch (prevUrl) {
        case 'freelanceJobs':
          setPreviousUrl([{ label: `${languageState.translation.SIDEBAR.FREELANCE_JOBS}`, link: `/private${APP_ROUTES.JOBS.BOARD}`}])
          break;
        case 'pendingApplications':
          setPreviousUrl([{ label: `${languageState.translation.SIDEBAR.PENDING_APPLICATIONS}`, link: `/private${APP_ROUTES.JOBS.PENDING_APPLICATIONS}`}])
          break;

    }
  };
  useEffect(() => {
    const geSubscription = async () => {
      await getDashBoardStats()
      const membership = await getSubscriptionsByUser()
      setMembershipState(membership)
    }
    geSubscription()
  }, []);
  useEffect(() => {
    const fetchApplications = async () => {
     const result = await getAllApplications()

      const applicationApplied = result.find((application) => application.job.id === id);

      setApplication(applicationApplied);
    };
    fetchApplications();
    
  }, []);
  

  useEffect(() => {
    const reqData = getQueryParamsFromObject({});
    getJobsSaved(reqData)
  }, [])

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getAsyncJob(id);
      checkIfApplied(id);
      checkIfSaved(id);
      getBreadcrumbPath()
    }
  }, [id]);

  useEffect(() => {
    if (jobsState && jobsState.jobSaved) {
      setJobSaved(jobsState.jobSaved)
    }
  }, [jobsState])
  useEffect(() => {
    if (job?.owner?.id === userInfo?.id) {
      setMyjob(true);
    }
  }, [userState, job]);
  useEffect(() => {
    if (job) {
      const array = [
        ...(job.missionsSkills || []),
        ...((job.tags || []).map((el) => {
          return { id: el, key: el }
        }))
      ]
      setAllSkills(array.sort((a, b) => a.key.localeCompare(b.key, undefined, { sensitivity: 'base' })))
    }

  }, [job])


  useEffect(() => {
    const getUserNote = async () => {
      if (application) {
        const fetchedNotes = await getNotes(application.id);
        setNotes(fetchedNotes);
      }
    };
    getUserNote()

  }, [application])

  const getAsyncJob = async (currentID) => {
    getJobDetail(currentID).then(
      (res) => {
        setJob(res.data);
        setIsLoading(false);
      },
      (err) => {
        setJob(null);
        setIsLoading(false);
      }
    );
  };
  const checkIfApplied = async (currentID) => {
    const result = await checkIsApplied(currentID);
    setIsApplied(result?.isApplied);
  };
  const checkIfSaved = async (currentId) => {
    const result = await checkIsSaved(currentId)
    setIsSaved(result.data)
  }
  const saveJob = async (id) => {
    const result = await saveNewJob(id)
    if (result && result.status === 201) {
      checkIfSaved(id)
      const reqData = getQueryParamsFromObject({});
      getJobsSaved(reqData)
      toast.success(languageState.translation.ALERT.JOB_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  };
  const deleteSavedJob = async () => {
    const result = await deleteSaved(id)
    if (result && result.status === 200) {
      checkIfSaved(id)
      toast.success(languageState.translation.ALERT.DELETE_JOB_SAVED, {
        position: 'bottom-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
    }
  }
  const handleApplication = async () => {
    console.log(dashboardState.dashBoardStats?.weeklyApplications)
    if(dashboardState.dashBoardStats?.weeklyApplications >= 1 && membershipState?.name === "Basic")
    {
      setShowSubscriptionModal(true);
      return;
    }
    dispatchDashboardState({
      type: DASHBOARD_STATE.types.UPDATE_WEEKLY_APPS,
      weeklyApplications: dashboardState.dashBoardStats?.weeklyApplications + 1,
    });
    if (!requirements.length || requirements.length <= 0) {
      !requirements.length || requirements.length <= 0 ? setRequirementsError(true) : setRequirementsError(false)
    } else {
      if (!isApplied) {

       const result = await applyJob(id, { validatedRequirements: requirements, form: personalMessage });
        if (result) {
          toast.success(languageState.translation.JOBBOARD.APPLICATION_SUCCESFULLY, {
            position: 'bottom-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          checkIfApplied(id);
        }
        return;
      }
      const result = await deleteApplication(id);
      if (result) {
        toast.success(languageState.translation.JOBBOARD.APPLICATION_REMOVED, {
          position: 'bottom-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        checkIfApplied(id);
      }
    }
  };


  const handleContentChange = (html) => {
    setPersonalMessage(html)
    setIsValidPersonalMessage(html.length >= 37)
  }
  const chatWithEmployer = () => {
    history.push(DEFAULT_PRIVATE_PATH + APP_ROUTES.CHAT, {
      email: job?.owner?.email,
    });
  };
  const handleShowShareWithFriend = () => {
    setIsShared((prev) => !prev);
  };

  if (isLoading) {
    return <JobsContainer>
      <LoaderPage />
    </JobsContainer>
  } else if (!job) {
    return (
      <JobsContainer>
        <Empty icon={undefined} message={undefined} />
      </JobsContainer>
    );
  }

  const changeRequirement = (e) => {
    if (e.target.checked) {
      setRequirements([...requirements, e.target.value])
    } else {
      let requirementFiltered = requirements.filter(el => el !== e.target.value)
      setRequirements(requirementFiltered)
    }
  }

  const openDetails = (id,) => {
    if (userState && userState.userInfo) {
      postViewJob(id)
      history.push('/private/jobs/job-details/' + id)
    }
  }

  const handleUpdateExtraData = async () => {
    const payload = {}
    const revenue = parseFloat(estimation);
    if (revenue >= 0) {
      payload.estimatedRevenue = revenue 
    }
    if(alias){
      payload.alias = alias 
    }
    await updateExtraData(application.id, payload);

      if (application.status === "SELECTED") {
        dispatchApplicationState({
          type: APPLICATIONS_STATE.types.EDIT_JOB_APPLICATION,
          applications: { ...application, estimatedRevenue: revenue },
        })
      }
      else {
        dispatchApplicationState({
          type: APPLICATIONS_STATE.types.EDIT_JOB_APPLICATION,
          applictaions: { ...application, estimatedRevenue: revenue },
        });
      }
    
  

  }
  const handleAddNoteClick = () => {
    setIsAddingNote(true);  
  };

  const handleNoteContentChange = (content) => {
    setNote(content);
  };

  const handleSaveNote = async () => {
    if (note) {
      await addNote(note, application?.id);
      const newNote = { note: note, created_at: new Date() };
      notes.unshift(newNote)
      setNote('')
    } 
    setIsAddingNote(false); 
  };

  return (
    <JobsContainer>
      <CardsHeaderFocus
        name={job?.functionTitle}
        breadcrumb
        className='job-breadcrumb'
        published={moment(job?.createdAt).format('DD MMMM YYYY')} type={undefined} subName={undefined}
        breadCrumbLinks={previousUrl}
        />

      <Container fluid className="mt-4 job-details">
        <div className="job-detail-header">
        </div>
        <Row>
          <Col lg="7" md="12">
            <Card>
              <CardBody>
                <div className="job-description">
                  <h1>{languageState.translation.COMMON.JOB_DESCRIPTION}</h1>
                  <p className="job-description-text" dangerouslySetInnerHTML={{ __html: job?.mainInfo }}>

                  </p>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>

                <div className="job-description">
                  <h1>{languageState.translation.COMMON.LIKE_TO_APPLY_FOR_THIS_JOB}</h1>
                  {allSkills && allSkills.length ? (
                    <>
                      <p className="font-weight-bold mb-2">
                        {languageState.translation.COMMON.HAVE_FOLLOWING_SKILLS_FOR_JOB}
                      </p>
                      <div className="required-skills">
                        <p className="required-skills-text">{languageState.translation.COMMON.REQUIRED_SKILLS}:</p>
                        {allSkills?.map((missionSkill) => (
                          <div key={missionSkill.id} className="skill">
                            <p className="skill-text text-nowwrap">
                              {missionSkill.key}
                            </p>
                          </div>
                        ))}
                      </div>
                    </>
                  ) : null}
                  {
                    job && job.requirements && job.requirements.length ?
                      <div>
                        <p className="font-weight-bold mb-2">
                          {languageState.translation.COMMON.EXTRA_REQUIREMENTS_FOR_THIS_JOB}
                        </p>
                        <div className='row mx-3'>
                          {
                            job.requirements.map((req, index) => (
                              <div className='mb-2 col-lg-12 col-md-12 col-sm-12' key={index}>
                                <Input
                                  className="custom-control-input position-relative"
                                  id={`requirement-${index}`}
                                  name={`requirement-${index}`}
                                  value={req}
                                  type="checkbox"
                                  onChange={(e) => changeRequirement(e)}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`requirement-${index}`}
                                >
                                  {req}
                                </label>
                              </div>
                            ))
                          }
                        </div>
                        {
                          requirementsError && requirements.length <= 0 && (
                            <span className='text-danger'>{languageState.translation.COMMON.EXTRA_REQUIREMENTS_FOR_THIS_JOB_ERROR_MESSAGE}</span>
                          )
                        }
                      </div> : null
                  }
                  <p className="font-weight-bold mb-2">
                    {languageState.translation.COMMON.PERSONAL_MESSAGE_FOR_THE_JOBPOSTER_LABEL}<span className='text-red'> *</span>
                  </p>
                  <ExEditor
                    name="personalMessage"
                    onChangeContent={handleContentChange}
                  />

                  {!isValidPersonalMessage && <p className='text-red'>
                    {languageState.translation.COMMON.MESSAGE_30_CARACTER}
                  </p>}
                </div>
                <div className='mt-3 d-flex justify-content-end'>
                  <Button onClick={() => isSaved ? deleteSavedJob() : saveJob(id)} color='danger'><i className="fas fa-bookmark fa-sm mr-1" /> {isSaved ? languageState.translation.COMMON.DELETE_SAVE_JOB : languageState.translation.COMMON.SAVE_JOB}</Button>
                  <Button disabled={!(!isApplied && isValidPersonalMessage && !(requirements.length < 0))} onClick={handleApplication} color='danger'>{languageState.translation.COMMON.BUTTONS.SEND_APPLICATION}</Button>
                </div>
              </CardBody>
            </Card>
            {/* <Card>
              <CardBody>

                <div className="job-description">
                  <h1>{languageState.translation.COMMON.EXTRA_INFO_ABOUT_THIS_TYPE_JOB}</h1>
                  <p className="job-description-text">
                    {job?.extraInfo}
                  </p>
                </div>
              </CardBody>
            </Card> */}
          </Col>
          <Col lg="5" md="12">
            <Card>
              <CardBody>
                <div className="job-description">
                  <h1>{languageState.translation.COMMON.EXTRA_INFO_ABOUT_THIS_JOB}</h1>
                  <div className='d-flex align-items-center flex-wrap'>
                    {job?.dailyBudget ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.dailyBudget} {languageState.translation.JOBBOARD.EURO_PER_DAY}
                        </p>
                      </div>
                    ) : null}
                    {/*  {job?.missionLocation ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.missionLocation}
                        </p>
                      </div>
                    ) : null} */}
                    {job?.nbViews ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.nbViews} {languageState.translation.JOBBOARD.VIEWS}
                        </p>
                      </div>
                    ) : null}

                    {/* {job?.company ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {languageState.translation.COMMON.COMPANY} {job.company}
                        </p>
                      </div>
                    ) : null} */}
                    {job?.country ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.country}
                        </p>
                      </div>
                    ) : null}
                    {job?.city ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.city}
                        </p>
                      </div>
                    ) : null}
                    {job?.missionCategories?.length ? (
                      job.missionCategories.map(el => (
                        <div key={el.id} className="skill mr-2 mb-2">
                          <p className="skill-text">
                            {el.label}
                          </p>
                        </div>
                      ))
                    ) : null}
                    {job?.hoursPerWeek ? (
                      <div className="skill mr-2 mb-2">
                        <p className="skill-text">
                          {job.hoursPerWeek} {languageState.translation.JOBBOARD.HOURS_PER_WEEK}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              </CardBody>
            </Card>
            {
              isApplied ?
                <div>
                  <Card>
                    <CardBody>
                      <Label className='mt-2 font-weight-bold'>{languageState.translation.JOBBOARD.DEAL_STAGE}</Label>
                      <Input
                        className='mt-2 font-weight-bold'
                        type='text'
                        placeholder={application?.status}
                        disabled
                      />
                      <Label className='mt-2 font-weight-bold'>{languageState.translation.COMMON.ASSIGN_POTENTIAL_REVENUE}</Label>
                      <Input
                        className='mt-2'
                        type='text'
                        placeholder={`€ ${application?.estimatedRevenue ? application?.estimatedRevenue : 0}`}
                        pattern="^€\s.*"
                        onChange={(e) => setEstimation(e.target.value)}
                      />

                      <Label className='mt-2 font-weight-bold'>{languageState.translation.JOBBOARD.ASSIGN_ALIAS}</Label>
                      <Input
                        className=''
                        placeholder={`${application?.alias ? application?.alias : ""}`}
                        onChange={(e) => setAlias(e.target.value)}
                      />
                      <Button color="primary" outline className="w-100 mt-2 shadow-none" onClick={handleUpdateExtraData}>
                        {languageState.translation.COMMON.BUTTONS.SAVE}
                      </Button>
                    </CardBody>
                  </Card>
                  <Card>
                    <CardBody>
                      <div className="d-flex justify-content-between">
                        <h1>{languageState.translation.JOBBOARD.HISTORY}</h1>
                        {
                          !isAddingNote &&
                          <Button color="exprimary" onClick={handleAddNoteClick}>{languageState.translation.COMMON.ADD_NOTE}</Button>
                        }
                      </div>
                      {isAddingNote && (
                        <div>
                          <Input
                            className='mt-2'
                            type="textarea"
                            rows="4"
                            value={note}
                            onChange={(e) => handleNoteContentChange(e.target.value)}
                            autoFocus
                          />
                          <Button className='w-2 mt-2' color='primary' onClick={handleSaveNote}>{languageState.translation.COMMON.ADD_NOTE}</Button>
                        </div>
                      )}
                      <div className='notes-card mt-3'>
                        <div>
                          <div className=''>
                            {notes.length > 0 ? (
                              notes.map((note, index) => (
                                <div key={index} className="note-item">
                                  <p className="note-content">{note.note}</p>
                                  <small>
                                    <p>{moment(note.created_at).format("DD-MM-YYYY HH:mm")}</p>
                                  </small>
                                </div>
                              ))
                            ) : (
                              <p className="text-muted">No notes available.</p>
                            )}
                          </div>
                        </div>
                      </div>

                    </CardBody>
                  </Card>
                </div> : ''
            }
            {job?.similarJobs?.length ?
              (<>
                <h1 className='mt-0'>{job.similarJobs.length} {languageState.translation.COMMON.SIMILAR_OPEN_POSITION}</h1>
                {job.similarJobs.map((job, index) => (
                  <Card key={`job-${index}`}>
                    <JobCard
                      {...job}
                      openDetails={openDetails}
                      isPublished
                      saveJob={saveJob}
                      jobSaved={jobSaved && jobSaved.find(el => el.mission.id === job.id)}
                    />
                  </Card>
                ))}
              </>) : null
            }
          </Col>
        </Row>
      </Container>
      <UpgradePremiumModal
        modalShow={showSubscriptionModal}
        closeModal={() => setShowSubscriptionModal(false)}
      />
    </JobsContainer>
  );
};

export default JobDetails;